import React from 'react';
import Modal from 'react-bootstrap/Modal';

class Portfolio extends React.Component{  
    state = {show: null};
    hideModal(id){
        this.setState({show:id});
    }  
    showModal(id){
        this.setState({show:id});
    }
    render(){
        return(
            <section id="portfolio">
                <div className="row section-intro">
                    <div className="col-twelve">
                        <h5>Portfolio</h5>
                        <h1>Some Stuffs.</h1>
                        <p>Those are some of the projects I developed during university and self learning;</p>
                        <hr></hr>
                    </div>   		
                </div>
                <div className="row portfolio-content">
               		<div className="col-twelve">{/* <!-- portfolio-wrapper --> */}
                        <div id="folio-wrapper" className="block-1-2 block-mob-full stack">
                            <div className="bgrid folio-item">
                                <div className="item-wrap">                                        
                                    <img src={require("../images/portfolio/quad_board.jpg")} alt="HIL Simulation"/>
                                    <div className="overlay" onClick = {() => this.showModal('modal-01')}>
                                       <div className="folio-item-table">
                                            <div className="folio-item-cell">
                                                <h3 className="folio-title">HIL Simulation</h3>	     					    
                                                <span className="folio-types">Quadcopter Model</span>
                                            </div>	                      	
                                        </div>                                           
                                    </div>
                                    <Modal show={this.state.show === 'modal-01'} style={{zIndex:1000}}>
                                        <div className="popup-modal slider mfp">	
                                            <div className="media">
                                                <img src={require("../images/portfolio/modals/m-quad.jpg")} 
												alt="HIL Simulation" />
                                            </div>      	
                                            <div className="description-box">
                                                <h4>Hardware In The Loop Simulation</h4>		      
                                                <p>Simulation of a quadcopter using a dynamic mathematical model of the aircraft, 
												solved using the 4th order Runge-Kutta numerical method and executed in real time with Linux RTAI. 
												The HIL system uses a microcontroller (PIC18F2580) for open-loop control of the simulated process through a Joystick that provides inputs to the system;</p>
                                                <div className="categories">Quadcopter Model</div>			               
                                            </div>
                                            <div className="link-box">
                                                <a className="popup-modal-dismiss" onClick={()=>this.hideModal('null')}>Close</a>
                                            </div>		      
                                        </div>
                                    </Modal>{/* <!-- /modal-01 --> */}
                                </div>	               
                            </div> {/* <!-- /folio-item --> */}

                            <div className="bgrid folio-item">
                                <div className="item-wrap">
                                    <video id='jcastro' autoPlay='autoplay' loop muted playsInline>
                                        <source src={require("../images/portfolio/jcastro.mp4")} type='video/mp4' />
                                    </video>
                                    <div className="overlay" onClick = {() => this.showModal('modal-02')}>             		                  
                                        <div className="folio-item-table">
                                            <div className="folio-item-cell">
                                                <h3 className="folio-title">Bidding Consultancy</h3>	     					    
                                                <span className="folio-types">ReactJS - Web Development</span>		     		
                                            </div> 	                      	
                                        </div>                    
                                    </div>
                                    <Modal show={this.state.show === 'modal-02'} style={{zIndex:1000}}>
                                        <div className="popup-modal slider mfp">	
                                            <div className="media">
                                            <video id='m-jcastro' autoPlay='autoplay' loop muted playsInline >
                                                <source src={require("../images/portfolio/modals/m-jcastro.mp4")} type='video/mp4' />
                                            </video>
                                            </div>      	
                                            <div className="description-box">
                                                <h4>JCastro - Bidding Consultancy Website</h4>		      
                                                <p>A website for bidding consultancy and legal advice where clients can have an overview of the services offered by the company, 
                                                    information on why to hire a consulting firm with legal support, as well as get in touch through the contact email section 
                                                    form or through social media. The website was developed in ReactJS with styled-components;</p>
                                                <p>You can check it here: <a href="https://jcastro.adv.br" target="_blank" rel="noopener noreferrer">JCastro Consultoria em Licitações</a></p>
                                                <div className="categories">ReactJS - Web Development</div>			               
                                            </div>
                                            <div className="link-box">
                                                <a className="popup-modal-dismiss" onClick = {()=>this.hideModal('null')}>Close</a>
                                            </div>		      
                                        </div>
                                    </Modal>
                                </div>
                            </div>

                            <div className="bgrid folio-item">
                                <div className="item-wrap">
                                    <video id='magyarflix' autoPlay='autoplay' loop muted playsInline >
                                        <source src={require("../images/portfolio/magyarflix.mp4")} type='video/mp4' />
                                    </video>
                                    <div className="overlay" onClick = {() => this.showModal('modal-03')}>
                                        <div className="folio-item-table">
                                            <div className="folio-item-cell">
                                                <h3 className="folio-title">MAGYARFLIX</h3>	     					    
                                                <span className="folio-types">ReactJS - Web Development</span>		     		
                                            </div>  	                      	
                                        </div>                    
                                    </div>
                                    <Modal show={this.state.show === 'modal-03'} style={{zIndex:1000}}>
                                        <div className="popup-modal slider mfp">
                                            <div className="media">
                                            <video id='m-magyarflix' autoPlay='autoplay' loop muted playsInline >
                                                <source src={require("../images/portfolio/modals/m-magyarflix.mp4")} type='video/mp4' />
                                            </video>
                                            </div>      	
                                            <div className="description-box">
                                                <h4>MAGYARFLIX - Your Number One Video Library About Hungary</h4>		      
                                                <p>MagyarFlix is your video gallery about Hungary; Here you can find useful guide videos about ruin pubs, traditional foods, drinks, touristic places, activities, curiosities, thermals and more.
                                                This web application was developed in ReactJS with styled-components, youtube Data API, storybook and more. The Contributing Area is under construction;</p>
                                                <p>You can check it here: <a href="https://www.patriciadourado.com/magyarflix/" target="_blank" rel="noopener noreferrer">magyarflix</a></p>
                                                <div className="categories">ReactJS - Web Development</div>			               
                                            </div>
                                            <div className="link-box">
                                                <a className="popup-modal-dismiss" onClick={()=>this.hideModal('null')}>Close</a>
                                            </div>		      
                                        </div>
                                    </Modal>{/* <!-- /modal-02 --> */}
                                </div>
                            </div> {/* <!-- /folio-item --> */}

                            <div className="bgrid folio-item">
                                <div className="item-wrap">
                                    <video id='react-weather' autoPlay='autoplay' loop muted playsInline >
                                        <source src={require("../images/portfolio/react-weather.mp4")} type='video/mp4' />
                                    </video>
                                    <div className="overlay" onClick={()=>this.showModal('modal-04')}>
                                        <div className="folio-item-table">
                                            <div className="folio-item-cell">
                                                <h3 className="folio-title">React Weather App</h3>	     					    
                                                <span className="folio-types">React Application</span>		     		
                                            </div> 	                      	
                                        </div>                    
                                    </div>
                                    <Modal show={this.state.show === 'modal-04'} style={{zIndex:1000}}>
                                        <div className="popup-modal slider mfp">	
                                            <div className="media">
                                                <video id='m-react-weather' autoPlay='autoplay' loop muted playsInline >
                                                    <source src={require("../images/portfolio/modals/m-react-weather.mp4")} type='video/mp4' />
                                                </video>
                                            </div>      	
                                            <div className="description-box">
                                                <h4>React Weather App</h4>		      
                                                <p>The application uses an API from openweathermap.org and displays some weather current information 
                                                as Temperature, Humidity and condition based on the City and Country name/code. It also shows two charts of 5 days Forecasts charts
                                                for Temperature;</p>
												<p>You can check it here: <a href="https://www.patriciadourado.com/react-weather-app/" target="_blank" rel="noopener noreferrer">Weather Application</a></p>
                                                <div className="categories">React Application</div>			               
                                            </div>
                                            <div className="link-box">
                                                <a className="popup-modal-dismiss" onClick = {()=>this.hideModal('null')}>Close</a>
                                            </div>		      
                                        </div>
                                    </Modal>{/* <!-- /modal-04 --> */}
                                </div>
                            </div>
                        </div> {/* <!-- /portfolio-wrapper -->  */}
   		            </div> {/*  <!-- /twelve -->    */}
   	            </div> {/* <!-- /portfolio-content --> */}	
	            <hr></hr>
                <div className="row section-intro">
                    <div className="col-twelve">
                        <h1>Registered Softwares</h1>
                        <hr></hr>
                        <ul align="left">			  			  
                            <li>Sistema de Seleção de Estudantes Aptos a Utilizarem o Restaurante Universitário da UNIVASF. 2018. BR512018051945-8 (INPI - Brazil); -<a href="System_for_selecting_students_able_to_use_the_university_restaurant_of_UNIVASF.pdf" target="_blank" rel="noopener noreferrer"><strong> Check Here</strong></a></li>
                            <li>Jogo de Reciclagem - Conscientização Ambiental com Foco na Coleta Seletiva de Lixo. 2018. BR512018051942-3 (INPI - Brazil); -<a href="Recycling_Game-Environmental_Awareness_with_a_focus_on_Selective_Garbage Collection.pdf" target="_blank" rel="noopener noreferrer"><strong> Check Here</strong></a></li>
                            <li>Acervo Fotográfico. 2018. BR512018051946-6 (INPI - Brazil); -<a href="Photographic-collection.pdf" target="_blank" rel="noopener noreferrer"><strong> Check Here</strong></a></li>
                        </ul>
                    </div>
                </div>
            </section> /* <!-- /portfolio -->  */
        );
    }
}

export default Portfolio;