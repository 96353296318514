import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faDev, faInstagram, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

class Intro extends React.Component{
	render(){
        return(   
            <section id="intro">		
				<div className="intro-overlay"></div>	        
				<div className="intro-content">
                    <div className="row">
                        <div className="col-twelve">
                            <h5>Hello, World.</h5>
                            <h1>I'm Patricia Dourado.</h1>
                            <p className="intro-position">
                                <span>Computer Engineer</span>
                                <span>Human Being</span> 
                            </p>
                            <a className="button stroke smoothscroll" href="#about" title="">More About Me <FontAwesomeIcon className='fas fa-chevron-down' icon={faChevronDown}/></a>
                        </div>    
                    </div>   		 		
                </div>
                <ul className="intro-social">        
                    <li><a href="https://github.com/patriciadourado/" target="_blank" rel="noopener noreferrer">
					<FontAwesomeIcon className='fab fa-github fa-xs' icon={faGithub}/></a></li>
                    <li><a href="https://linkedin.com/in/patricia-dourado/" target="_blank" rel="noopener noreferrer">
					<FontAwesomeIcon className='fab fa-linkedin fa-xs' icon={faLinkedinIn}/></a></li>				  
                    <li><a href="https://dev.to/static_pat" target="_blank" rel="noopener noreferrer">
					<FontAwesomeIcon className='fab fa-dev fa-xs' icon={faDev}/></a></li>				  
                    <li><a href="https://instagram.com/douradome/" target="_blank" rel="noopener noreferrer">
					<FontAwesomeIcon className='fab fa-instagram fa-xs' icon={faInstagram}/></a></li>				  
                    <li><a href="https://twitter.com/static_pat" target="_blank" rel="noopener noreferrer">
					<FontAwesomeIcon className='fab fa-twitter fa-xs' icon={faTwitter}/></a></li>
                </ul> 
            </section>
        );
    }

}

export default Intro;