import { faDev, faGithub, faInstagram, faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faLongArrowAltUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

class Footer extends React.Component{
    render(){
        return(
            <React.Fragment>
                <footer>
                    <div className="row">
                        <div className="col-six tab-full pull-right social">
                            <ul className="footer-social">        
                            <li><a href="https://github.com/patriciadourado/" target="_blank" rel="noopener noreferrer">
							<FontAwesomeIcon className='fab fa-github fa-xs' icon={faGithub}/></a></li>
                            <li><a href="https://linkedin.com/in/patricia-dourado/" target="_blank" rel="noopener noreferrer">
							<FontAwesomeIcon className='fab fa-linkedin fa-xs' icon={faLinkedinIn}/></a></li>
                            <li><a href="https://dev.to/static_pat" target="_blank" rel="noopener noreferrer">
					        <FontAwesomeIcon className='fab fa-dev fa-xs' icon={faDev}/></a></li>					  
                            <li><a href="https://instagram.com/douradome/" target="_blank" rel="noopener noreferrer">
							<FontAwesomeIcon className='fab fa-instagram fa-xs' icon={faInstagram}/></a></li>				  
                            <li><a href="https://twitter.com/static_pat" target="_blank" rel="noopener noreferrer">
							<FontAwesomeIcon className='fab fa-twitter fa-xs' icon={faTwitter}/></a></li>
                        </ul> 
                    </div>
                    <div className="col-six tab-full">
                        <div className="copyright">
                            <span>© Copyright <a href="https://www.patriciadourado.com" target="_blank" rel="noopener noreferrer">Patricia Dourado</a> 2019.</span> 
                            </div>		                  
                        </div>
                        <div id="go-top">
                            <a className="smoothscroll" title="Back to Top" href="#intro"><FontAwesomeIcon className='fas fa-long-arrow-alt-up fa-lgs' icon={faLongArrowAltUp}/></a>
                        </div>
                    </div>
                    <div id="preloader"> 
                        <div id="loader"></div>
                    </div>
                </footer>  
                
            </React.Fragment>
        );
    }

}

export default Footer;