import React from 'react';

class About extends React.Component{
    render(){
        return(
            <section id="about">  
                <div className="row section-intro">
                    <div className="col-twelve">
                        <h5>About</h5>
                        <h1>A little about myself.</h1>
                        <div className="intro-info">
                            <img title="Patricia Dourado" src={require("../images/profile-pic.jpg")} alt="Patricia Dourado"/>
                            <p className="lead">
                                Hi! I am <strong>Patricia Dourado</strong>, an enthusiastic apprentice who has spent a lot of time developing in low-level languages, had to work with scripting languages and is now exploring the web development.
                                I am someone who loves traveling, nature, knowing different cultures and solve problems. 
                                I always bring with me the idea that we are not perfect and we must be kind and respectful to all living beings.                             
                                When I am doing something I like to be deeply involved to achieve the best results otherwise I get bored.
                            </p>
                        </div>   			
                    </div>   		
                </div>
                <div className="row about-content">
                    <div className="col-six tab-full">
                        <h3>Profile</h3>
                        <p>I am always trying to improve myself in any direction my professional life guides me. Basically I want to develop the environment I am inserted;</p>
                        <ul className="info-list">
                            <li>
                                <strong>Fullname:</strong>
                                <span>Patricia Nunes Dourado</span>
                            </li>
                            {/* <li>
                                <strong>Company:</strong>
                                <span>AltoQi</span>
                            </li> */}
                            <li>
                                <strong>Currently Job:</strong>
                                <span>C++ Programmer</span>
                            </li>
                            <li>
                                <strong>Email:</strong>
                                <span>contact@patriciadourado.com</span>
                            </li>
                            <li>
                                <strong>Hobbies:</strong>
                                <span>Volleyball, Play Canastra, Counter-Strike</span>
                            </li>
                            <li>
                                <strong>Addicts:</strong>
                                <span>Coffee, Traveling, Potato Soup</span>
                            </li>
                        </ul>
                    </div>
                    <div className="col-six tab-full">
                        <h3>Skills</h3>
                        <p>During my university course I used to code in low level languages for embedded systems applications, now I am learning more about Flask, ReactJS and a lot of others stuffs; 
                            Check out my blog clicking here: <a href="https://www.patriciadourado.com/frompat" target="_blank" rel="noopener noreferrer"><strong> frompat.</strong></a></p>
                            {/* <div className="section-skills"> */}
                                <div className="skills">
                                    <strong>C/C++</strong>
                                </div>
                                <div className="skills2">
                                    <strong>JAVASCRIPT</strong>
                                </div>
                                <div className="skills">
                                    <strong>PYTHON</strong>
                                </div>         
                                <div className="skills2">
                                    <strong>HTML</strong>
                                </div>
                                <div className="skills">
                                    <strong>SQL DATABASES</strong> 
                                </div>
                                <div className="skills2">
                                    <strong>CSS</strong>
                                </div>
                                
                            {/* </div> */}
                            {/* <ul className="skill-bars">
								<li>
									<div className="progress percent80"></div>
									<strong>C/C++</strong>
								</li>
								<li>
									<div className="progress percent80"></div>
									<strong>SQL DATABASES</strong>
								</li>
								<li>
									<div className="progress percent60"></div>
									<strong>Javascript</strong>
								</li>
								<li>
									<div className="progress percent60"></div>
									<strong>HTML+CSS</strong>
								</li>
								<li>
									<div className="progress percent60"></div>
									<strong>PYTHON</strong>
								</li>
								<li>
									<div className="progress percent40"></div>
									<strong>MATLAB</strong>
								</li>
                            </ul> */}
                    </div>
                </div>
                <div className="row button-section">
                    <div className="col-twelve">
                        <a href="#contact" title="Keep in touch" className="button stroke smoothscroll">Keep in touch</a>
                        <a href="https://linkedin.com/in/patricia-dourado/" target="_blank" rel="noopener noreferrer" title="Download CV" className="button button-primary">Download CV</a>
                    </div>   		
                </div>
            </section>
        );
    }

}

export default About;