import React from 'react';

class Menu extends React.Component{
    render(){
        return(
            <header>
                <div className="row">
                    <div className="top-bar">
                        <a id="menu" className="menu-toggle" href="#menu"><span>Menu</span></a>
                        <div className="logo">
                            <a href="#">MENU</a>
                        </div>		      
                        <nav id="main-nav-wrap">
                            <ul className="main-navigation">
                                <li><a className="smoothscroll" href="#intro" title="">Home</a></li>
                                <li><a className="smoothscroll" href="#about" title="">About</a></li>
                                <li><a className="smoothscroll" href="#resume" title="">Resume</a></li>
                                <li><a className="smoothscroll" href="#portfolio" title="">Portfolio</a></li>
                                <li><a className="smoothscroll" href="#contact" title="">Contact</a></li>
                            </ul>
                        </nav>    		
                    </div>
                    <div className="blog">
                        <a href="https://www.patriciadourado.com/frompat" target="_blank" rel="noopener noreferrer">
                            <img src={require("../images/blog-pic.jpg")} alt="blog" title="fromtpat - blog" />    
                        </a>
                    </div>
                </div> 	 
            </header>	
        );
    }
}

export default Menu;